import React from 'react';

const SearchBar = ({ keyword, setKeyword }) => {
	const BarStyling = {
		width: '60rem',
		background: '#F2F1F9',
		border: 'none',
		padding: '1rem',
		margin: '1rem'
	};
	function setKeyword(e) {
		console.log(e);
	}

	return (
		<input
			style={BarStyling}
			key='random1'
			value={keyword}
			placeholder={'Search'}
			onChange={e => setKeyword(e.target.value)}
		/>
	);
};

export default SearchBar;
