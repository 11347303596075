import { Modal, Row, Col } from 'react-bootstrap';
import ModalCloseButton from '../Core/Modal/ModalCloseButton';
import ModalBackButton from '../Core/Modal/ModalBackButton';
import React, { useEffect, useState } from 'react';
import { theme } from '../../theme';

export default function RepairChildSelectModal({
	isShowing,
	toggleRepair,
	toggle,
	repairs,
	repairParent,
	itemRepairs
}) {
	const [activeRepairs, setActiveRepairs] = useState([]);

	useEffect(() => {
		setActiveRepairs(() => {
			let activeRepairs = [];
			let ar = itemRepairs.map(x => (x ? x['uuid'] : undefined));
			if (repairs) {
				for (let repair of repairs) {
					if (repair) {
						if (ar.includes(repair['uuid'])) {
							activeRepairs.push(repair['uuid']);
						}
					}
				}
			}
			return activeRepairs;
		});
	}, [itemRepairs, repairs]);

	return (
		<Modal show={isShowing} onHide={toggle} animation={false}>
			<Modal.Header>
				<ModalBackButton onClick={toggle} />
				<Modal.Title>{repairParent && repairParent['name']}</Modal.Title>
				<ModalCloseButton onClick={toggle} />
			</Modal.Header>

			<Modal.Body>
				{repairs &&
					repairs.map((repair, repairIndex) => {
						return (
							<Row>
								<Col>
									<button
										style={{
											background: activeRepairs.includes(repair.uuid)
												? theme.primaryHover
												: theme.primaryLight,
											borderRadius: 'none',
											color: 'black',
											border: '3px solid black',
											fontSize: '12px',
											margin: '5px',
											maxWidth: '20vh',
											width: '100%',
											height: '100%'
										}}
										type='checkbox'
										onClick={() => {
											toggleRepair(repair);
											toggle();
										}}
										key={repairIndex}>
										{repair.name + ' $' + repair.price}
									</button>
								</Col>
								<Col></Col>
							</Row>
						);
					})}
			</Modal.Body>
		</Modal>
	);
}
