import styled from 'styled-components';

export const StyledMenuButton = styled.div`
	a:first-child.nav-link {
		color: ${({ theme }) => theme.orange};
	}

	&:hover {
		color: ${({ theme }) => theme.primaryHover};
	}

	a:first-child {
		color: ${({ theme }) => theme.orange};
	}
`;

export const StyledMenu = styled.nav`
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: ${({ theme }) => theme.grayFour};
	position: fixed;
	left: 0;
	right: 0;
	z-index: 5;
	transition: transform 0.3s ease-in-out;
	height: ${({ theme }) => theme.menuHeight + 'px'};

	.navbar-light .nav-link {
		font-size: 12px;
		text-transform: uppercase;
		text-decoration: none;

		&:hover {
			color: ${({ theme }) => theme.primaryHover};
		}

		&:focus {
			color: ${({ theme }) => theme.primaryLight};
		}

		&:focus:hover {
			color: ${({ theme }) => theme.primaryHover};
		}
	}

	.navbar-light .navbar-nav .nav-item .nav-link {
		color: ${({ theme }) => theme.orange};
		&:hover {
			color: ${({ theme }) => theme.primaryHover};
		}
	}

	.navbar-light .navbar-nav .nav-item .dropdown-menu {
		background: ${({ theme }) => theme.grayFour};
		border: ${({ theme }) => theme.primaryLight};
	}

	.navbar-light .navbar-nav .nav-item .dropdown-item {
		background: ${({ theme }) => theme.grayFour};

		&:hover {
			color: ${({ theme }) => theme.primaryHover};
		}
	}

	.navbar-light .navbar-nav .nav-dropdown-right .dropdown-menu {
		text-align: right;
		transform: translateX(-36.5%);
	}
`;
