import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './ChoicesPage.css';

class ChoicesPage extends Component {
	render() {
		return (
			<div>
				<h1 style={{ fontWeight: 'bolder', marginTop: '5%' }}>
					EXCLUSIVE SHOE & LEATHER REPAIR CENTER
				</h1>
				<h2>SELECT YOUR SHOEBER SERVICE</h2>
				<Link to='/home' style={{ textDecoration: 'none' }}>
					<Card style={{ marginBottom: '5%' }}>
						<Card.Body className='choices'>
							<Card.Title as='h2'>PICK UP AND DELIVER</Card.Title>
							<Card.Text>
								HAVE A SHOEBERINA PICK UP YOUR REPAIR AND DELIVER IT WHEN IT IS
								READY.
							</Card.Text>
						</Card.Body>
					</Card>
				</Link>

				<Link to='/home' style={{ textDecoration: 'none' }}>
					<Card style={{ marginBottom: '5%' }}>
						<Card.Body className='choices'>
							<Card.Title as='h2'>DROPBOX</Card.Title>
							<Card.Text>
								CHOOSE YOUR LOCAL SHOEBER DROPBOX TO DROP OF YOUR REPAIR AND GET
								IT DELIVERED TO YOU WHEN IT IS READY.
							</Card.Text>
						</Card.Body>
					</Card>
				</Link>
				<Link to='/home' style={{ textDecoration: 'none' }}>
					<Card>
						<Card.Body className='choices'>
							<Card.Title as='h2'>MAIL IN</Card.Title>
							<Card.Text>
								CHOOSE A MAIL COURIER TO HAVE YOUR REPAIR SENT DIRECTLY TO
								SHOEBER.
							</Card.Text>
						</Card.Body>
					</Card>
				</Link>
			</div>
		);
	}
}
export default ChoicesPage;
