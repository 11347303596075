import React, { Component } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import ShoeberLine from '../Core/ShoeberLine';
import { ShoeberBackendApi } from '../../api/ShoeberBackendApi';
import SearchBar from './searchbar';
import ShoeberButton from '../Core/ShoeberButton';
import { Link } from 'react-router-dom';
import './EmployeePortal.css';

export default class EmployeePortal extends Component {
	render() {
		return (
			<div>
				<h1 style={{ fontWeight: 'bolder', margin: '5%' }}>Employee Portal</h1>
				<SearchBar />
				<Link to='/home' style={{ textDecoration: 'none' }}>
					<Card style={{ marginBottom: '5%' }}>
						<Card.Body className='choices'>
							<Card.Title as='h2'>San Fernando Valley</Card.Title>
							<Card.Text>
								North Hollywood, Van Nuys, Studio City, Encino, Woodland Hills,
								Valley Village, Burbank, Glendale, Valley Glen, Shermam Oaks,
								Sylmar, North Hills, Tarzana, Granada Hills
							</Card.Text>
						</Card.Body>
					</Card>
				</Link>

				<Link to='/home' style={{ textDecoration: 'none' }}>
					<Card style={{ marginBottom: '5%' }}>
						<Card.Body className='choices'>
							<Card.Title as='h2'>Central Los Angeles</Card.Title>
							<Card.Text>
								90004, 90005, 90006, 90012, 90013, 90014, 90015, 90017, 90019,
								90021, 90026, 90027, 90028, 90035, 90036, 90038, 90039, 90046,
								90048, 90057, 90068, 90069, 90071.
							</Card.Text>
						</Card.Body>
					</Card>
				</Link>
				<Link to='/home' style={{ textDecoration: 'none' }}>
					<Card style={{ marginBottom: '5%' }}>
						<Card.Body className='choices'>
							<Card.Title as='h2'>Westside Los Angeles</Card.Title>
							<Card.Text>
								90024, 90025, 90034, 90035, 90049, 90056, 90064, 90066, 90067,
								90073, 90077, 90094, 90210, 90212, 90230, 90232, 90272, 90291,
								90292, 90401, 90402, 90403, 90404, 90405.
							</Card.Text>
						</Card.Body>
					</Card>
				</Link>
				<Link to='/home' style={{ textDecoration: 'none' }}>
					<Card style={{ marginBottom: '5%' }}>
						<Card.Body className='choices'>
							<Card.Title as='h2'>Antelope Valley</Card.Title>
							<Card.Text>Need zip codes</Card.Text>
						</Card.Body>
					</Card>
				</Link>
				<Link to='/home' style={{ textDecoration: 'none' }}>
					<Card style={{ marginBottom: '5%' }}>
						<Card.Body className='choices'>
							<Card.Title as='h2'>San Gabriel Valley</Card.Title>
							<Card.Text>Need zip codes</Card.Text>
						</Card.Body>
					</Card>
				</Link>
			</div>
		);
	}
}
