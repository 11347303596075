import LoadOnce from '../../hooks/loadOnce';
import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import ShoeberButton from '../Core/ShoeberButton';
import { ShoeberBackendApi } from '../../api/ShoeberBackendApi';
import Errors from '../../utils/Errors';
import useRedirectWithAlert from '../../hooks/useRedirectWithAlert';
import { INFO, SUCCESS } from '../Core/ShoeberAlert';
import styled from 'styled-components';
import DropoffAddress from './WorkingOrder/DropoffAddress';
import PickupAddress from './WorkingOrder/PickupAddress';
import LocationSelect from './WorkingOrder/LocationSelect';
import Calendar from './WorkingOrder/Calendar';
import ShoeberLine from '../Core/ShoeberLine';
import { format } from 'react-string-format';
import Loading from '../Core/Loading/Loading';
import ContactInfo, { PHONE_NUMBER, EMAIL } from './WorkingOrder/ContactInfo';
import {
	createLocalStorage,
	MY_REPAIR_ITEMS_NAME
} from '../../utils/localStorageManager/localStorage';
import OrderType from './WorkingOrder/OrderType';
import AccountPaymentInput from './WorkingOrder/AccountPaymentInput';
import { useManagedStorageInput } from '../../hooks/managedStorageInput';
import usePayments, { STRIPE_TOKEN_ID } from '../../hooks/usePayments';
import {
	createSessionStorage,
	MY_WORKING_ORDER,
	USER_ACCOUNT_INFO,
	PAYMENT_INFO
} from '../../utils/sessionStorageManager/sessionStorage';
import MyCartItems from '../Core/ShoeberShoppingCart/MyCartItems';
import { theme } from '../../theme';
import { PICKUP_ORDER, DROPBOX_ORDER } from './WorkingOrder/OrderType';
import ShoeberErrorMessage from '../Core/ShoeberErrorMessage';
import {
	BILLING_ADDRESS_ONE,
	BILLING_ADDRESS_TWO,
	BILLING_POSTAL_CODE,
	BILLING_CITY,
	BILLING_STATE,
	BILLING_COUNTRY
} from './WorkingOrder/BillingAddress';

import { formatPhoneNumber } from '../../utils/phoneNumber';

import { BILLING_ADDRESS_SAME_AS_DROPOFF } from './WorkingOrder/AccountPaymentInput';

export const StyledCheckoutForm = styled.div`
	.form-group {
		text-align: left;
	}
`;

export const CUSTOMER_NOTES = 'customer_notes';
export const ITEMS = 'items';

export default function Checkout({ setActiveEditItem }) {
	const myCartLocalStorageManager = createLocalStorage(
		MY_REPAIR_ITEMS_NAME,
		'array'
	);

	const userInfoSessionManager = createSessionStorage(
		USER_ACCOUNT_INFO,
		'json'
	);

	const redirectWithAlert = useRedirectWithAlert();

	const myWorkingOrderSessionManager = createSessionStorage(
		MY_WORKING_ORDER,
		'json'
	);

	const paymentInfoSessionManager = createSessionStorage(
		PAYMENT_INFO,
		'json',
		undefined,
		300000
	);

	const customerNotesInput = useManagedStorageInput(
		myWorkingOrderSessionManager,
		CUSTOMER_NOTES
	);

	const payments = usePayments();

	const [userLoggedIn, setUserIsLoggedIn] = useState(true);

	const [additionalErrorMessage, setAdditionalErrorMessage] = useState('');

	const [myCart, setCart] = useState([]);

	const checkoutPageLoad = LoadOnce();

	const [loading, setIsLoading] = useState(false);

	const [orderType, setOrderType] = useState(undefined);

	const orderApi = ShoeberBackendApi.getOrderApi();

	const [errorMessageHash, setErrorMessageHash] = useState({});

	const [myWorkingOrder, setMyWorkingOrder] = useState({});

	const [cardData, setCardData] = useState({});

	useEffect(() => {
		if (checkoutPageLoad.canLoad()) {
			myCartLocalStorageManager.addCallback(() => {
				let myNewRepairCart = myCartLocalStorageManager.get();
				if (myNewRepairCart.length === 0) {
					redirectWithAlert.redirectWithAlert(
						'/',
						'Item Removed',
						'Add a item to repair',
						INFO
					);
				}
				setCart(myNewRepairCart);
			});

			userInfoSessionManager.addCallback(() => {
				setUserIsLoggedIn(userInfoSessionManager.get('userType') !== undefined);
			});

			myWorkingOrderSessionManager.addCallback(() => {
				setMyWorkingOrder(myWorkingOrderSessionManager.get());
			});

			paymentInfoSessionManager.addCallback(() => {
				setCardData(paymentInfoSessionManager.get());
			});

			customerNotesInput.init();
		}
	}, [
		checkoutPageLoad,
		customerNotesInput,
		myCartLocalStorageManager,
		myWorkingOrderSessionManager,
		paymentInfoSessionManager,
		redirectWithAlert,
		userInfoSessionManager
	]);

	function handleSuccessfulOrder(response) {
		let itemCount = myCart.length;
		myWorkingOrderSessionManager.reset();
		myCartLocalStorageManager.reset();
		redirectWithAlert.redirectWithAlert(
			'/',
			'Order Created',
			format(
				'Your order with {0} items needing repair has been created.',
				itemCount
			),
			SUCCESS
		);
	}

	function handleErrorResponse(errorResponse) {
		let errorManager = new Errors(errorResponse);
		let newErrorMessageHash = {};
		Object.keys(errorManager.errors).forEach(errorKey => {
			if (errorKey !== ITEMS) {
				newErrorMessageHash[errorKey] = errorManager.errorMessage(errorKey);
			} else {
				newErrorMessageHash[errorKey] =
					'Sorry we seem to be having issues processing your item repair request.';
			}
		});
		setErrorMessageHash(newErrorMessageHash);
	}

	function handleSaveCardData(cardData) {
		if (userLoggedIn) {
			paymentInfoSessionManager.set(cardData);
		} else {
			setCardData(cardData);
		}
	}

	const handleSubmit = evt => {
		evt.preventDefault();
		let order = {};
		setIsLoading(true);

		let billingAddressInfo = [
			BILLING_ADDRESS_ONE,
			BILLING_ADDRESS_TWO,
			BILLING_POSTAL_CODE,
			BILLING_CITY,
			BILLING_STATE,
			BILLING_COUNTRY
		];

		let sendableCart = [];
		myCart.forEach(item => {
			let sendableItem = {
				services: [],
				item: item.clean_cloud_id,
				brand: item.brand || 'Unknown',
				name: item.name || 'Unknown',
				color: item.color || 'Unknown'
			};

			item.repairsRequested.forEach(repair => {
				sendableItem.services.push(repair.uuid);
			});
			sendableCart.push(sendableItem);
		});
		order[ITEMS] = sendableCart;

		let billingAddressSameAsDropoffAddress =
			myWorkingOrder[BILLING_ADDRESS_SAME_AS_DROPOFF];

		let stripTokenId = payments.getTokenFromPaymentInfo(cardData);

		if (stripTokenId === undefined) {
			setErrorMessageHash(payments.paymentErrors);
			setIsLoading(false);
		} else {
			Object.keys(myWorkingOrder).forEach(key => {
				let value = myWorkingOrder[key];
				if (value || value === false) {
					if (
						billingAddressSameAsDropoffAddress === false ||
						!billingAddressInfo.includes(key)
					) {
						order[key] = value;
					}
				}
			});

			if (userLoggedIn) {
				delete order[PHONE_NUMBER];
				delete order[EMAIL];
			} else if (order[PHONE_NUMBER]) {
				order[PHONE_NUMBER] = formatPhoneNumber(order[PHONE_NUMBER]);
			}

			order[STRIPE_TOKEN_ID] = stripTokenId;

			if (orderType === PICKUP_ORDER) {
				orderApi
					.orderCustomerPickupPost(order)
					.then(response => {
						handleSuccessfulOrder(response);
						setIsLoading(false);
					})
					.catch(errorResponse => {
						handleErrorResponse(errorResponse);
						setIsLoading(false);
					});
			} else if (orderType === DROPBOX_ORDER) {
				orderApi
					.orderCustomerDropboxPost(order)
					.then(response => {
						handleSuccessfulOrder(response);
						setIsLoading(false);
					})
					.catch(errorResponse => {
						handleErrorResponse(errorResponse);
						setIsLoading(false);
					});
			} else {
				setAdditionalErrorMessage('Please select a order type.');
				setIsLoading(false);
			}
		}
	};

	return (
		<>
			<Loading loading={loading} />
			<StyledCheckoutForm>
				<Form style={{ paddingTop: '10px' }}>
					<Row>
						<Col>
							<h2>Create Order</h2>
						</Col>
					</Row>
					<ShoeberLine />
					<Row>
						<Col>
							<h3>My Items</h3>
							<MyCartItems
								setActiveEditItem={setActiveEditItem}
								color={theme.primaryDark}
							/>
						</Col>
					</Row>
					{errorMessageHash[ITEMS] && (
						<Row>
							<Col>
								<ShoeberErrorMessage
									style={{ textAlign: 'left' }}
									message={errorMessageHash[ITEMS]}
								/>
							</Col>
						</Row>
					)}
					<OrderType orderType={orderType} setOrderType={setOrderType} />
					<ShoeberLine />
					<PickupAddress
						errorMessageHash={errorMessageHash}
						orderType={orderType}
					/>
					<LocationSelect
						orderType={orderType}
						errorMessageHash={errorMessageHash}
					/>
					<ShoeberLine />

					<DropoffAddress errorMessageHash={errorMessageHash} />
					<ShoeberLine />
					<AccountPaymentInput
						errorMessageHash={errorMessageHash}
						saveCardData={handleSaveCardData}
						cardData={cardData}
					/>
					<ShoeberLine />
					<ContactInfo errorMessageHash={errorMessageHash} />
					<Form.Group style={{ paddingTop: '8px' }}>
						<Form.Label>Additional Info</Form.Label>
						<Form.Control
							as='textarea'
							{...customerNotesInput.bind}
							placeholder='Additional Info'
							isInvalid={errorMessageHash[CUSTOMER_NOTES]}
						/>
						<Form.Control.Feedback type='invalid'>
							{errorMessageHash[CUSTOMER_NOTES]}
						</Form.Control.Feedback>
					</Form.Group>
					<ShoeberLine />
					<ShoeberButton text='Create Order' onClick={handleSubmit} />
					{additionalErrorMessage && (
						<ShoeberErrorMessage message={additionalErrorMessage} />
					)}
				</Form>
			</StyledCheckoutForm>
		</>
	);
}
